import React, { useEffect, useState } from "react";
import classes from "./AddMainServiceModal.module.css";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { addMainServiceApi } from "../../Data/Api";
import { toast } from "react-toastify";
import Loader from "../Loading-States/Loader";

const AddMainServiceModal = ({
  clientId,
  addMainServiceModal,
  closeMainServiceModal,
  hitApi,
}) => {
  const [serviceName, setServiceName] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [servicePacks, setServicePacks] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [operator, setOperator] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [recipientsArrayObject, setRecipientsArrayObject] = useState([]);

  const [loader, setLoader] = useState("none");

  useEffect(() => {
    if (addMainServiceModal) {
      setServiceName("");
      setServiceStatus("");
      setServicePacks("");
      setUsername("");
      setPassword("");
      setCountry("");
      setOperator("");
      setRecipients([]);
      setRecipientsArrayObject([]);
    }
  }, [addMainServiceModal]);

  const handleRecipientChange = (event) => {
    const {
      target: { value },
    } = event;
    setRecipients(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const filteredRecipients = defaultRecipients?.filter((data) =>
      recipients.includes(data["email"])
    );
    setRecipientsArrayObject(filteredRecipients);
  }, [recipients]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      serviceName.trim().length == 0 ||
      servicePacks == "" ||
      serviceStatus == "" ||
      username.trim().length == 0 ||
      password.trim().length == 0 ||
      country.trim().length == 0 ||
      operator.trim().length == 0 ||
      recipientsArrayObject.length == 0
    ) {
      toast.error("Input Fields cannot be empty!");
      return;
    }
    try {
      setLoader("block");
      let token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${addMainServiceApi}`,
        {
          clientId: clientId,
          data: {
            serviceName: serviceName,
            status: serviceStatus,
            packs: servicePacks,
            username: username,
            password: password,
            country: country,
            operator: operator,
            recipients: recipientsArrayObject,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response...");
      setLoader("none");
      toast.success("Main Service Added Successfully!");
      hitApi(clientId);
      closeMainServiceModal();
    } catch (error) {
      setLoader("none");
      console.log(error, "error");
      toast.error(
        error?.response?.data?.err ||
          error?.message ||
          error?.data?.message ||
          error?.response?.data
      );
    }
  };

  return (
    <Modal
      open={addMainServiceModal}
      onClose={closeMainServiceModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modalStyle}>
        <Loader value={loader} />
        <form className={classes.form} onSubmit={submitHandler}>
          <TextField
            id="serviceName"
            label="Service Name"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            variant="outlined"
            sx={{ width: "100%" }}
          />
          <TextField
            id="serviceStatus"
            type="number"
            label="Service Status"
            variant="outlined"
            value={serviceStatus}
            onChange={(e) => setServiceStatus(e.target.value)}
            sx={{ width: "100%" }}
          />
          <TextField
            id="servicePacks"
            label="Service Packs"
            type="number"
            variant="outlined"
            value={servicePacks}
            onChange={(e) => setServicePacks(e.target.value)}
            sx={{ width: "100%" }}
          />
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ width: "100%" }}
          />
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ width: "100%" }}
          />
          <TextField
            id="country"
            label="Country"
            variant="outlined"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            sx={{ width: "100%" }}
          />
          <TextField
            id="operator"
            label="Operator"
            variant="outlined"
            value={operator}
            onChange={(e) => setOperator(e.target.value)}
            sx={{ width: "100%" }}
          />

          <FormControl fullWidth>
            <InputLabel id="recipients-select-label">
              Select Recipients
            </InputLabel>
            <Select
              labelId="recipients-select-label"
              id="recipients-select"
              multiple
              value={recipients}
              input={<OutlinedInput label="Select Recipients" />}
              onChange={handleRecipientChange}
            >
              {defaultRecipients.map((recipient, i) => {
                return (
                  <MenuItem key={i} value={recipient["email"]}>
                    {recipient["email"]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            disabled={loader === "block"}
            color="error"
            sx={{ width: "100%" }}
          >
            Add
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddMainServiceModal;

const defaultRecipients = [
  {
    email: "nitin.jha@visiontrek.in",
    password: "0",
    status: "1",
    type: "to",
  },
  {
    email: "manoj@visiontrek.in",
    password: "0",
    status: "1",
    type: "cc",
  },
  {
    email: "harish.mehra@visiontrek.in",
    password: "0",
    status: "1",
    type: "cc",
  },
  {
    email: "rajan.sharma@visiontrek.in",
    password: "0",
    status: "1",
    type: "cc",
  },
  {
    email: "ashok.kumar@visiontrek.in",
    password: "0",
    status: "1",
    type: "cc",
  },
];
