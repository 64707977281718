import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./ServiceModal.module.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddIcon from "@mui/icons-material/Add";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import {
  addServiceDetailsApi,
  getServiceInfoDetailsApi,
  updateServiceInfoDetailsApi,
} from "../../Data/Api";
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import Loading from "../Loading-States/Loading";
import Loader from "../Loading-States/Loader";

function SubServiceInfoChildModal({
  subServiceInfoModal,
  closeSubServiceInfoModal,
  setSubServiceInfo,
  subServiceInfo,
  addSubService,
}) {
  const handleClose = () => {
    closeSubServiceInfoModal();
  };

  const [prizesModal, setPrizesModal] = useState(false);
  const [prices, setPrices] = useState([]);
  const [pricesAddedByUser, setPricesAddedByUser] = useState([]);

  const handlePriceChange = (event) => {
    const {
      target: { value },
    } = event;
    setPrices(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const filteredPrices = pricesAddedByUser?.filter((data) =>
      prices.includes(data["pack"])
    );
    setSubServiceInfo((prevValue) => ({
      ...prevValue,
      prices: filteredPrices,
    }));
  }, [prices]);

  const closePrizesModal = () => {
    setPrizesModal(false);
  };

  return (
    <>
      <Modal
        open={subServiceInfoModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        {/* <Box sx={childStyle}> */}
        <Box className={classes.childModalStyle}>
          <div className={classes.form}>
            <TextField
              id="serviceName"
              label="Service Name"
              variant="outlined"
              value={subServiceInfo.serviceName}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  serviceName: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="subServiceName"
              label="Sub Service Name"
              variant="outlined"
              value={subServiceInfo.subServiceName}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  subServiceName: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="serviceStatus"
              label="Service Status"
              variant="outlined"
              value={subServiceInfo.serviceStatus}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  serviceStatus: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="status"
              label="status"
              variant="outlined"
              value={subServiceInfo.status}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  status: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              type="number"
              id="packs"
              label="Packs"
              variant="outlined"
              value={subServiceInfo.packs}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  packs: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <div className={classes.selector_wrapper}>
              <FormControl fullWidth>
                <InputLabel id="country-select-label">Select Prizes</InputLabel>
                <Select
                  labelId="country-select-label"
                  id="country-simple-select"
                  label="Select Prizes List"
                  sx={{ width: "100%" }}
                  multiple
                  value={prices}
                  input={<OutlinedInput label="Select Recipients" />}
                  onChange={handlePriceChange}
                >
                  {pricesAddedByUser.map((data, i) => {
                    return (
                      <MenuItem key={i} value={data?.pack}>
                        {data?.pack}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <IconButton
                color="error"
                aria-label="add prizes"
                onClick={() => setPrizesModal(true)}
              >
                <AddIcon />
              </IconButton>
            </div>

            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                addSubService();
                handleClose();
              }}
            >
              Add the Sub Service
            </Button>
          </div>
          <PrizesSubModal
            prizesModal={prizesModal}
            closePrizesModal={closePrizesModal}
            pricesAddedByUser={pricesAddedByUser}
            setPricesAddedByUser={setPricesAddedByUser}
          />
        </Box>
      </Modal>
    </>
  );
}

function EditSubServiceInfoModal({
  data,
  editSubServiceModal,
  closeEditSubServiceModal,
  setSubServiceInfo,
  subServiceInfo,
  handleEditTheSubService,
}) {
  const handleClose = () => {
    closeEditSubServiceModal();
  };

  const [prizesModal, setPrizesModal] = useState(false);
  const [prices, setPrices] = useState([]);
  const [pricesAddedByUser, setPricesAddedByUser] = useState([]);

  const handlePriceChange = (event) => {
    const {
      target: { value },
    } = event;
    setPrices(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const filteredPrices = pricesAddedByUser?.filter((data) =>
      prices.includes(data["pack"])
    );

    setSubServiceInfo((prevValue) => ({
      ...prevValue,
      prices: filteredPrices,
    }));
  }, [prices]);

  const closePrizesModal = () => {
    setPrizesModal(false);
  };

  useEffect(() => {
    if (editSubServiceModal?.state) {
      setSubServiceInfo((prevValue) => ({
        ...prevValue,
        serviceName:
          data.subServiceInfo[editSubServiceModal.idx].serviceName || "",
        subServiceName:
          data.subServiceInfo[editSubServiceModal.idx].subServiceName || "",
        serviceStatus:
          data.subServiceInfo[editSubServiceModal.idx].serviceStatus || "",
        status: data.subServiceInfo[editSubServiceModal.idx].status || "",
        packs: data.subServiceInfo[editSubServiceModal.idx].packs || "",
        prizes: data.subServiceInfo[editSubServiceModal.idx].prices || [],
      }));
      // setPrices(data.subServiceInfo[editSubServiceModal.idx].prices);
      setPricesAddedByUser(data.subServiceInfo[editSubServiceModal.idx].prices);
    }
  }, [editSubServiceModal]);

  return (
    <>
      <Modal
        open={editSubServiceModal?.state}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        {/* <Box sx={childStyle}> */}
        <Box className={classes.childModalStyle}>
          <div className={classes.form}>
            <TextField
              id="serviceName"
              label="Service Name"
              value={subServiceInfo.serviceName}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  serviceName: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="subServiceName"
              label="Sub Service Name"
              value={subServiceInfo.subServiceName}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  subServiceName: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="serviceStatus"
              label="Service Status"
              value={subServiceInfo.serviceStatus}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  serviceStatus: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="status"
              label="status"
              value={subServiceInfo.status}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  status: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="packs"
              label="Packs"
              type="number"
              value={subServiceInfo.packs}
              onChange={(e) =>
                setSubServiceInfo((prevValue) => ({
                  ...prevValue,
                  packs: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <div className={classes.selector_wrapper}>
              <FormControl fullWidth>
                <InputLabel id="country-select-label">Select Prizes</InputLabel>
                <Select
                  labelId="country-select-label"
                  id="country-simple-select"
                  label="Select Prizes List"
                  sx={{ width: "100%" }}
                  multiple
                  value={prices}
                  input={<OutlinedInput label="Select Recipients" />}
                  onChange={handlePriceChange}
                >
                  {pricesAddedByUser.map((data, i) => {
                    return (
                      <MenuItem key={i} value={data?.pack}>
                        {data?.pack}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <IconButton
                color="error"
                aria-label="add prizes"
                onClick={() => setPrizesModal(true)}
              >
                <AddIcon />
              </IconButton>
            </div>

            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                handleEditTheSubService(editSubServiceModal?.idx);
                handleClose();
              }}
            >
              Edit Sub Service
            </Button>
          </div>
          <PrizesSubModal
            prizesModal={prizesModal}
            closePrizesModal={closePrizesModal}
            pricesAddedByUser={pricesAddedByUser}
            setPricesAddedByUser={setPricesAddedByUser}
          />
        </Box>
      </Modal>
    </>
  );
}

function EditServiceDetailsModal({
  editServiceDetailsModal,
  closeEditServiceDetailsModal,
  data,
  setServiceDetailsInfo,
  serviceDetailsInfo,
  handleEditTheServiceDetails,
}) {
  const handleClose = () => {
    closeEditServiceDetailsModal();
  };

  useEffect(() => {
    if (editServiceDetailsModal?.state) {
      setServiceDetailsInfo((prevValue) => ({
        ...prevValue,
        serviceName:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].serviceName ||
          "",
        subServiceName:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].subServiceName ||
          "",
        database:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].database || "",
        serviceUrl:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].serviceUrl || "",
        callbackUrl:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].callbackUrl ||
          "",
        price: data.serviceDetailsInfo[editServiceDetailsModal.idx].price || "",
        pack: data.serviceDetailsInfo[editServiceDetailsModal.idx].pack || "",
        frontendpath:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].frontendpath ||
          "",
        backendpath:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].backendpath ||
          "",
        description:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].description ||
          "",
        country:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].country || "",
        operator:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].operator || "",
        empId: data.serviceDetailsInfo[editServiceDetailsModal.idx].empId || "",
        serviceVideoUrl:
          data.serviceDetailsInfo[editServiceDetailsModal.idx]
            .serviceVideoUrl || "",
        serverIp:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].serverIp || "",
        promotionUrl:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].promotionUrl ||
          "",
        promotionStartDate:
          data.serviceDetailsInfo[editServiceDetailsModal.idx]
            .promotionStartDate || "",
        lastPromotionDate:
          data.serviceDetailsInfo[editServiceDetailsModal.idx]
            .lastPromotionDate || "",
        callbackRequest:
          data.serviceDetailsInfo[editServiceDetailsModal.idx]
            .callbackRequest || "",
        callbackResponse:
          data.serviceDetailsInfo[editServiceDetailsModal.idx]
            .callbackResponse || "",
        chargingStatus:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].chargingStatus ||
          "",
        chargingApi:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].chargingApi ||
          "",
        chargingRequest:
          data.serviceDetailsInfo[editServiceDetailsModal.idx]
            .chargingRequest || "",
        chargingResponse:
          data.serviceDetailsInfo[editServiceDetailsModal.idx]
            .chargingResponse || "",
        freeTrail:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].freeTrail || "",
        freeTrailDays:
          data.serviceDetailsInfo[editServiceDetailsModal.idx].freeTrailDays ||
          "",
      }));
    }
  }, [editServiceDetailsModal]);

  return (
    <>
      <Modal
        open={editServiceDetailsModal?.state}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        {/* <Box sx={childStyle}> */}
        <Box className={classes.childModalStyle}>
          <div className={classes.form}>
            <TextField
              id="serviceName"
              label="Service Name"
              value={serviceDetailsInfo?.serviceName}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  serviceName: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="subServiceName"
              label="Sub Service Name"
              value={serviceDetailsInfo?.subServiceName}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  subServiceName: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="database"
              label="Database"
              value={serviceDetailsInfo?.database}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  database: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="serviceUrl"
              label="Service Url"
              value={serviceDetailsInfo?.serviceUrl}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  serviceUrl: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="callbackUrl"
              label="Callback Url"
              value={serviceDetailsInfo?.callbackUrl}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  callbackUrl: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="price"
              label="Price"
              value={serviceDetailsInfo?.price}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  price: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="pack"
              label="Pack"
              variant="outlined"
              value={serviceDetailsInfo?.pack}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  pack: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="frontendpath"
              label="Frontend Path"
              variant="outlined"
              value={serviceDetailsInfo?.frontendpath}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  frontendpath: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="backendpath"
              label="Backend Path"
              value={serviceDetailsInfo?.backendpath}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  backendpath: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="description"
              label="Description"
              value={serviceDetailsInfo?.description}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  description: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              value={serviceDetailsInfo?.country}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  country: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="operator"
              label="Operator"
              variant="outlined"
              value={serviceDetailsInfo?.operator}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  operator: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="empId"
              label="empId"
              value={serviceDetailsInfo?.empId}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  empId: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="serviceVideoUrl"
              label="Service Video Url"
              variant="outlined"
              value={serviceDetailsInfo?.serviceVideoUrl}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  serviceVideoUrl: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="serverIp"
              label="Server Ip"
              variant="outlined"
              value={serviceDetailsInfo?.serverIp}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  serverIp: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="promotionUrl"
              label="Promotion Url"
              variant="outlined"
              value={serviceDetailsInfo?.promotionUrl}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  promotionUrl: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="promotionStartDate"
              label="Promotion Start Date"
              variant="outlined"
              value={serviceDetailsInfo?.promotionStartDate}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  promotionStartDate: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="lastPromotionDate"
              label="Last Promotion Date"
              variant="outlined"
              value={serviceDetailsInfo?.lastPromotionDate}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  lastPromotionDate: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="callbackRequest"
              label="Callback Request"
              variant="outlined"
              value={serviceDetailsInfo?.callbackRequest}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  callbackRequest: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="callbackResponse"
              label="Callback Response"
              variant="outlined"
              value={serviceDetailsInfo?.callbackResponse}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  callbackResponse: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="chargingStatus"
              label="Charging Status"
              variant="outlined"
              value={serviceDetailsInfo?.chargingStatus}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  chargingStatus: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingApi"
              label="Charging Api"
              variant="outlined"
              value={serviceDetailsInfo?.chargingApi}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  chargingApi: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingRequest"
              label="Charging Request"
              variant="outlined"
              value={serviceDetailsInfo?.chargingRequest}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  chargingRequest: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingResponse"
              label="Charging Response"
              variant="outlined"
              value={serviceDetailsInfo?.chargingResponse}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  chargingResponse: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="freeTrail"
              label="Free Trial"
              variant="outlined"
              value={serviceDetailsInfo?.freeTrail}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  freeTrail: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="freeTrailDays"
              label="Free Trail Days"
              variant="outlined"
              value={serviceDetailsInfo?.freeTrailDays}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  freeTrailDays: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                handleEditTheServiceDetails(editServiceDetailsModal?.idx);
                handleClose();
              }}
            >
              Edit Sub Service
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

function PrizesSubModal({
  prizesModal,
  closePrizesModal,
  pricesAddedByUser,
  setPricesAddedByUser,
}) {
  const handleClose = () => {
    closePrizesModal();
  };

  const [price, setPrice] = useState("");
  const [pack, setPack] = useState("");
  const [status, setStatus] = useState("");
  const [serviceName, setServiceName] = useState("");

  const handlePriceAdd = () => {
    setPricesAddedByUser((prevValue) => [
      ...prevValue,
      { price, pack, status, serviceName },
    ]);
    closePrizesModal();
  };
  return (
    <>
      <Modal
        open={prizesModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className={classes.childModalStyle}>
          <div className={classes.form}>
            <TextField
              id="price"
              label="Price"
              variant="outlined"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              sx={{ width: "100%" }}
            />
            <TextField
              id="pack"
              label="Pack"
              value={pack}
              onChange={(e) => setPack(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="status"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="serviceName"
              label="Service Name"
              variant="outlined"
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
              sx={{ width: "100%" }}
            />

            <Button
              sx={{ width: "100%" }}
              variant="contained"
              color="primary"
              onClick={handlePriceAdd}
            >
              Add the Price
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

function ServiceDetailsInfoModal({
  serviceDetailsInfoModal,
  closeServiceDetailsInfoModal,
  serviceDetailsInfo,
  setServiceDetailsInfo,
  addServiceDetailsInfo,
}) {
  const handleClose = () => {
    closeServiceDetailsInfoModal();
  };

  return (
    <>
      <Modal
        open={serviceDetailsInfoModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className={classes.childModalStyle}>
          <div className={classes.form}>
            <TextField
              id="serviceName"
              label="Service Name"
              value={serviceDetailsInfo?.serviceName}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  serviceName: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="subServiceName"
              label="Sub Service Name"
              value={serviceDetailsInfo?.subServiceName}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  subServiceName: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="database"
              label="Database"
              value={serviceDetailsInfo?.database}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  database: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="serviceUrl"
              label="Service Url"
              value={serviceDetailsInfo?.serviceUrl}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  serviceUrl: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="callbackUrl"
              label="Callback Url"
              value={serviceDetailsInfo?.callbackUrl}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  callbackUrl: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="price"
              label="Price"
              value={serviceDetailsInfo?.price}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  price: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />

            <TextField
              id="pack"
              label="Pack"
              variant="outlined"
              value={serviceDetailsInfo?.pack}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  pack: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="frontendpath"
              label="Frontend Path"
              variant="outlined"
              value={serviceDetailsInfo?.frontendpath}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  frontendpath: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="backendpath"
              label="Backend Path"
              value={serviceDetailsInfo?.backendpath}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  backendpath: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="description"
              label="Description"
              value={serviceDetailsInfo?.description}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  description: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              value={serviceDetailsInfo?.country}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  country: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="operator"
              label="Operator"
              variant="outlined"
              value={serviceDetailsInfo?.operator}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  operator: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="empId"
              label="empId"
              value={serviceDetailsInfo?.empId}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  empId: e.target.value,
                }))
              }
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <TextField
              id="serviceVideoUrl"
              label="Service Video Url"
              variant="outlined"
              value={serviceDetailsInfo?.serviceVideoUrl}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  serviceVideoUrl: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="serverIp"
              label="Server Ip"
              variant="outlined"
              value={serviceDetailsInfo?.serverIp}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  serverIp: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="promotionUrl"
              label="Promotion Url"
              variant="outlined"
              value={serviceDetailsInfo?.promotionUrl}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  promotionUrl: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="promotionStartDate"
              label="Promotion Start Date"
              variant="outlined"
              value={serviceDetailsInfo?.promotionStartDate}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  promotionStartDate: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="lastPromotionDate"
              label="Last Promotion Date"
              variant="outlined"
              value={serviceDetailsInfo?.lastPromotionDate}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  lastPromotionDate: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="callbackRequest"
              label="Callback Request"
              variant="outlined"
              value={serviceDetailsInfo?.callbackRequest}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  callbackRequest: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="callbackResponse"
              label="Callback Response"
              variant="outlined"
              value={serviceDetailsInfo?.callbackResponse}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  callbackResponse: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="chargingStatus"
              label="Charging Status"
              variant="outlined"
              value={serviceDetailsInfo?.chargingStatus}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  chargingStatus: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingApi"
              label="Charging Api"
              variant="outlined"
              value={serviceDetailsInfo?.chargingApi}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  chargingApi: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingRequest"
              label="Charging Request"
              variant="outlined"
              value={serviceDetailsInfo?.chargingRequest}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  chargingRequest: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="chargingResponse"
              label="Charging Response"
              variant="outlined"
              value={serviceDetailsInfo?.chargingResponse}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  chargingResponse: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="freeTrail"
              label="Free Trial"
              variant="outlined"
              value={serviceDetailsInfo?.freeTrail}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  freeTrail: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <TextField
              id="freeTrailDays"
              label="Free Trail Days"
              variant="outlined"
              value={serviceDetailsInfo?.freeTrailDays}
              onChange={(e) =>
                setServiceDetailsInfo((prevValue) => ({
                  ...prevValue,
                  freeTrailDays: e.target.value,
                }))
              }
              sx={{ width: "100%" }}
            />

            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                addServiceDetailsInfo();
                handleClose();
              }}
            >
              Add Service Details
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

const ServiceModal = ({ open, closeHandler, hitApi, idOfSubService }) => {
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    serviceName: "",
    status: "",
    packs: 0,
    username: "",
    password: "",
    country: "",
    operator: "",
    recipients: [],
    subServiceInfo: [],
    serviceDetailsInfo: [],
  });

  useEffect(() => {
    if (open && hitApi && idOfSubService) {
      fetchData();
    }
    if (open && !hitApi) {
      setData({
        serviceName: "",
        status: "",
        packs: 0,
        username: "",
        password: "",
        country: "",
        operator: "",
        recipients: [],
        subServiceInfo: [],
        serviceDetailsInfo: [],
      });
    }
  }, [open, hitApi, idOfSubService]);

  const defaultRecipients = [
    {
      email: "nitin.jha@visiontrek.in",
      password: "0",
      status: "1",
      type: "to",
      serviceName: "TrueMove",
    },
    {
      email: "manoj@visiontrek.in",
      password: "0",
      status: "1",
      type: "cc",
      serviceName: "TrueMove",
    },
    {
      email: "harish.mehra@visiontrek.in",
      password: "0",
      status: "1",
      type: "cc",
      serviceName: "TrueMove",
    },
    {
      email: "rajan.sharma@visiontrek.in",
      password: "0",
      status: "1",
      type: "cc",
      serviceName: "TrueMove",
    },
    {
      email: "ashok.kumar@visiontrek.in",
      password: "0",
      status: "1",
      type: "cc",
      serviceName: "TrueMove",
    },
  ];

  const [subServiceInfoModal, setSubServiceInfoModal] = useState(false);
  const closeSubServiceInfoModal = () => {
    setSubServiceInfoModal(false);
  };

  const [serviceDetailsInfoModal, setServiceDetailsInfoModal] = useState(false);

  const closeServiceDetailsInfoModal = () => {
    setServiceDetailsInfoModal(false);
  };

  const [subServiceInfo, setSubServiceInfo] = useState({
    serviceName: "",
    subServiceName: "",
    serviceStatus: "",
    status: "",
    packs: 0,
    prices: [],
  });

  const [subServiceInfoArray, setSubServiceInfoArray] = useState([]);

  const [serviceDetailsInfo, setServiceDetailsInfo] = useState({
    serviceName: "",
    subServiceName: "",
    database: "",
    serviceUrl: "",
    callbackUrl: "",
    price: "",
    pack: "",
    frontendpath: "",
    backendpath: "",
    description: "",
    country: "",
    operator: "",
    empId: "",
    serviceVideoUrl: "",
    serverIp: "",
    promotionUrl: "",
    promotionStartDate: "",
    lastPromotionDate: "",
    callbackRequest: "",
    callbackResponse: "",
    chargingStatus: "",
    chargingApi: "",
    chargingRequest: "",
    chargingResponse: "",
    freeTrail: "",
    freeTrailDays: "",
  });
  const [serviceDetailsInfoArray, setServiceDetailsInfoArray] = useState([]);

  const [editSubServiceModal, setEditSubServiceModal] = useState({
    state: false,
    idx: null,
  });

  const [editServiceDetailsModal, setEditServiceDetailsModal] = useState({
    state: false,
    idx: null,
  });

  const closeEditSubServiceModal = () => {
    setEditSubServiceModal({ state: false, idx: null });
  };

  const closeEditServiceDetailsModal = () => {
    setEditServiceDetailsModal({
      state: false,
      idx: null,
    });
  };

  const addServiceDetailsInfo = () => {
    setServiceDetailsInfoArray((prevValue) => [
      ...prevValue,
      serviceDetailsInfo,
    ]);
  };

  const addSubService = () => {
    setSubServiceInfoArray((prevValue) => [...prevValue, subServiceInfo]);
  };

  const handleEditTheSubService = (idx) => {
    const updatedSubServiceInfoArray = subServiceInfoArray.map((item, index) =>
      index === idx ? { ...item, ...subServiceInfo } : item
    );
    setSubServiceInfoArray(updatedSubServiceInfoArray);
  };

  const handleEditTheServiceDetails = (idx) => {
    const updatedServiceDetailsInfoArray = serviceDetailsInfoArray.map(
      (item, index) =>
        index === idx ? { ...item, ...serviceDetailsInfo } : item
    );
    setServiceDetailsInfoArray(updatedServiceDetailsInfoArray);
  };

  useEffect(() => {
    setData((prevValue) => ({
      ...prevValue,
      subServiceInfo: subServiceInfoArray,
    }));
  }, [subServiceInfoArray]);

  useEffect(() => {
    setData((prevValue) => ({
      ...prevValue,
      serviceDetailsInfo: serviceDetailsInfoArray,
    }));
  }, [serviceDetailsInfoArray]);

  const [recipients, setRecipients] = useState([]);

  const handleRecipientChange = (event) => {
    const {
      target: { value },
    } = event;
    setRecipients(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const filteredRecipients = defaultRecipients?.filter((data) =>
      recipients.includes(data["email"])
    );
    setData((prevValue) => ({
      ...prevValue,
      recipients: filteredRecipients,
    }));
  }, [recipients]);

  const handleFullFormSubmission = async () => {
    try {
      // const response = await axios.post(`${addServiceDetailsApi}`, {
      //   data: JSON.stringify(data),
      // });

      const response = await axios.post(`${addServiceDetailsApi}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response, "response,,,");

      if (response?.data?.response == 1) {
        toast.success("Data Inserted Successfully!");
        closeHandler();
      } else {
        toast.error("Data Insertion Failure as some fields are empty!");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const isSubServiceInfoValid = () => {
    return (
      Array.isArray(data.subServiceInfo) &&
      data.subServiceInfo.length > 0 &&
      data.subServiceInfo.every(
        (info) =>
          info.serviceName.trim().length != 0 &&
          info.subServiceName.trim().length != 0 &&
          info.serviceStatus.trim().length != 0 &&
          info.status.trim().length != 0 &&
          Array.isArray(info.prices) &&
          info.prices.length > 0
      )
    );
  };

  const isServiceDetailsInfoValid = () => {
    return (
      Array.isArray(data?.serviceDetailsInfo) &&
      data?.serviceDetailsInfo?.length > 0 &&
      data?.serviceDetailsInfo.every(
        (info) =>
          info.serviceName?.trim().length !== 0 &&
          info.subServiceName?.trim().length !== 0 &&
          info.database?.trim().length !== 0 &&
          info.serviceUrl?.trim().length !== 0 &&
          info.callbackUrl?.trim().length !== 0 &&
          info.price?.trim().length !== 0 &&
          info.pack?.trim().length !== 0 &&
          info.frontendpath?.trim().length !== 0 &&
          info.backendpath?.trim().length !== 0 &&
          info.description?.trim().length !== 0 &&
          info.country?.trim().length !== 0 &&
          info.operator?.trim().length !== 0 &&
          info.empId?.trim()?.length !== 0 &&
          info.serviceVideoUrl?.trim().length !== 0 &&
          info.serverIp?.trim()?.length !== 0 &&
          info.promotionUrl?.trim().length !== 0 &&
          info.promotionStartDate?.trim().length !== 0 &&
          info.lastPromotionDate?.trim().length !== 0 &&
          info.callbackRequest?.trim().length !== 0 &&
          info.callbackResponse?.trim().length !== 0 &&
          info.chargingStatus?.trim().length !== 0 &&
          info.chargingApi?.trim().length !== 0 &&
          info.chargingRequest?.trim().length !== 0 &&
          info.chargingResponse?.trim().length !== 0 &&
          info.freeTrail?.trim().length !== 0 &&
          info.freeTrailDays?.trim().length !== 0
      )
    );
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let token = localStorage.getItem("userToken");
      let headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        `${getServiceInfoDetailsApi}`,
        {
          id: idOfSubService,
        },
        {
          headers: headers,
        }
      );

      setData({
        serviceName: response?.data?.data?.serviceName,
        status: response?.data?.data?.status,
        packs: response?.data?.data?.packs,
        username: response?.data?.data?.username,
        password: response?.data?.data?.password,
        country: response?.data?.data?.country,
        operator: response?.data?.data?.operator,
        recipients: response?.data?.data?.recipients,
        subServiceInfo: response?.data?.data?.subServiceInfo,
        serviceDetailsInfo: response?.data?.data?.serviceDetailsInfo,
      });

      setSubServiceInfoArray(response?.data?.data?.subServiceInfo);
      setServiceDetailsInfoArray(response?.data?.data?.serviceDetailsInfo);

      setLoading(false);

      console.log(response, "response...");
    } catch (error) {
      setLoading(false);
      console.log(error, "error...");
    }
  };

  const handleFullFormEdit = async () => {
    try {
      let token = localStorage.getItem("userToken");

      const response = await axios.post(
        `${updateServiceInfoDetailsApi}`,
        { data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response,,,");

      // if (response?.data?.response == 1) {
      //   toast.success("Data Inserted Successfully!");
      //   closeHandler();
      // } else {
      //   toast.error("Data Insertion Failure as some fields are empty!");
      // }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modalStyle}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.modal_stepper}>
              <div
                onClick={() => setIndex(0)}
                className={`${classes.stepper} ${index >= 0 && classes.active}
            ${
              data?.serviceName?.trim().length != 0 &&
              data?.status?.trim().length != 0 &&
              data?.username?.trim().length != 0 &&
              data?.password?.trim().length != 0 &&
              data?.country?.trim().length != 0 &&
              data?.operator?.trim().length != 0 &&
              data?.recipients.length != 0 &&
              classes.fulfilled
            }
            `}
              >
                <div
                  className={`${classes.step}  ${
                    data?.serviceName?.trim().length != 0 &&
                    data?.status?.trim().length != 0 &&
                    data?.username?.trim().length != 0 &&
                    data?.password?.trim().length != 0 &&
                    data?.country?.trim().length != 0 &&
                    data?.operator?.trim().length != 0 &&
                    data?.recipients.length != 0 &&
                    classes.fulfilled
                  }`}
                >
                  <p className={classes.step_no}>1</p>
                </div>
                <p className={classes.step_info}>Service-Info</p>
              </div>

              <div
                className={`${classes.line_container} ${
                  index >= 0 && classes.active
                }
            ${
              data?.serviceName?.trim().length != 0 &&
              data?.status?.trim().length != 0 &&
              data?.username?.trim().length != 0 &&
              data?.password?.trim().length != 0 &&
              data?.country?.trim().length != 0 &&
              data?.operator?.trim().length != 0 &&
              data?.recipients.length != 0 &&
              classes.fulfilled
            }
            `}
              >
                <div className={classes.line}></div>
              </div>

              <div
                onClick={() => setIndex(1)}
                className={`${classes.stepper} ${index >= 1 && classes.active}
            ${isSubServiceInfoValid() && classes.fulfilled}
            `}
              >
                <div
                  className={`${classes.step} ${
                    isSubServiceInfoValid() && classes.fulfilled
                  }`}
                >
                  <p className={classes.step_no}>2</p>
                </div>
                <p className={classes.step_info}>SubService-Info</p>
              </div>

              <div
                className={`${classes.line_container} ${
                  index >= 1 && classes.active
                } 
            ${isSubServiceInfoValid() && classes.fulfilled}
            `}
              >
                <div className={classes.line}></div>
              </div>

              <div
                onClick={() => setIndex(2)}
                className={`${classes.stepper} ${index >= 2 && classes.active}
                ${isServiceDetailsInfoValid() && classes.fulfilled}
            `}
              >
                <div
                  className={`${classes.step} ${
                    isServiceDetailsInfoValid() && classes.fulfilled
                  }`}
                >
                  <p className={classes.step_no}>3</p>
                </div>
                <p className={classes.step_info}>Service Details</p>
              </div>
            </div>

            {index === 0 ? (
              <div className={classes.form}>
                <TextField
                  id="serviceName"
                  label="Service Name"
                  value={data?.serviceName}
                  onChange={(e) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      serviceName: e.target.value,
                    }))
                  }
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <TextField
                  id="serviceStatus"
                  label="Service Status"
                  variant="outlined"
                  value={data?.status}
                  onChange={(e) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      status: e.target.value,
                    }))
                  }
                  sx={{ width: "100%" }}
                />
                <TextField
                  id="servicePacks"
                  label="Service Packs"
                  type="number"
                  variant="outlined"
                  value={data?.packs}
                  onChange={(e) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      packs: e.target.value,
                    }))
                  }
                  sx={{ width: "100%" }}
                />
                <TextField
                  id="username"
                  label="Username"
                  variant="outlined"
                  value={data?.username}
                  onChange={(e) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      username: e.target.value,
                    }))
                  }
                  sx={{ width: "100%" }}
                />
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  value={data?.password}
                  onChange={(e) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      password: e.target.value,
                    }))
                  }
                  sx={{ width: "100%" }}
                />
                <TextField
                  id="country"
                  label="Country"
                  variant="outlined"
                  value={data?.country}
                  onChange={(e) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      country: e.target.value,
                    }))
                  }
                  sx={{ width: "100%" }}
                />
                <TextField
                  id="operator"
                  label="Operator"
                  variant="outlined"
                  value={data?.operator}
                  onChange={(e) =>
                    setData((prevValue) => ({
                      ...prevValue,
                      operator: e.target.value,
                    }))
                  }
                  sx={{ width: "100%" }}
                />

                <FormControl fullWidth>
                  <InputLabel id="recipients-select-label">
                    Select Recipients
                  </InputLabel>
                  <Select
                    labelId="recipients-select-label"
                    id="recipients-select"
                    multiple
                    value={recipients}
                    input={<OutlinedInput label="Select Recipients" />}
                    onChange={handleRecipientChange}
                  >
                    {defaultRecipients.map((recipient, i) => {
                      return (
                        <MenuItem key={i} value={recipient["email"]}>
                          {recipient["email"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={() => setIndex(1)}
                >
                  Next
                </Button>
              </div>
            ) : index === 1 ? (
              <>
                <div className={classes.form}>
                  <button
                    className={classes.add_btn}
                    onClick={() => setSubServiceInfoModal(true)}
                  >
                    Add Sub-Service Info
                    <AddIcon />
                  </button>

                  <div className={classes.table_container}>
                    <div className={classes.table_sub_container}>
                      <table className={classes.table}>
                        <tbody>
                          <tr className={classes.tr_th}>
                            <th className={classes.th}>Index</th>
                            <th className={classes.th}>ServiceName</th>
                            <th className={classes.th}>SubService Name</th>
                            <th className={classes.th}>Service Status</th>
                            <th className={classes.th}>Status</th>
                            <th className={classes.th}>packs</th>
                            <th className={classes.th}>prices</th>
                            <th className={classes.th}>Edit</th>
                          </tr>

                          {data.subServiceInfo?.map((dataItem, idx) => {
                            return (
                              <tr className={classes.tr} key={idx}>
                                <td className={classes.td}>{idx + 1}</td>
                                <td className={classes.td}>
                                  {dataItem?.serviceName}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.subServiceName}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.serviceStatus}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.status}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.packs}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.prices?.map((item) =>
                                    JSON.stringify(item)
                                  )}
                                </td>
                                <td className={classes.td}>
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      setEditSubServiceModal({
                                        state: true,
                                        idx: idx,
                                      })
                                    }
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <SubServiceInfoChildModal
                  subServiceInfoModal={subServiceInfoModal}
                  closeSubServiceInfoModal={closeSubServiceInfoModal}
                  setSubServiceInfo={setSubServiceInfo}
                  subServiceInfo={subServiceInfo}
                  addSubService={addSubService}
                />
                <EditSubServiceInfoModal
                  editSubServiceModal={editSubServiceModal}
                  closeEditSubServiceModal={closeEditSubServiceModal}
                  data={data}
                  setSubServiceInfo={setSubServiceInfo}
                  subServiceInfo={subServiceInfo}
                  handleEditTheSubService={handleEditTheSubService}
                />
              </>
            ) : (
              <>
                <div className={classes.form}>
                  <div className={classes.btns_wrapper}>
                    <button
                      className={classes.add_btn}
                      onClick={() => setServiceDetailsInfoModal(true)}
                    >
                      Add Service Details
                      <AddIcon />
                    </button>

                    {data?.serviceName?.trim().length != 0 &&
                      data?.status?.trim().length != 0 &&
                      data?.username?.trim().length != 0 &&
                      data?.password?.trim().length != 0 &&
                      data?.country?.trim().length != 0 &&
                      data?.operator?.trim().length != 0 &&
                      data?.recipients.length != 0 &&
                      isSubServiceInfoValid() &&
                      isServiceDetailsInfoValid() && (
                        <Button
                          variant="contained"
                          color="success"
                          // onClick={handleFullFormSubmission}
                          onClick={
                            idOfSubService !== null
                              ? handleFullFormEdit
                              : handleFullFormSubmission
                          }
                        >
                          {idOfSubService !== null ? "Edit" : "Submit"}
                        </Button>
                      )}
                  </div>

                  <div className={classes.table_container}>
                    <div className={classes.table_sub_container}>
                      <table className={classes.table}>
                        <tbody>
                          <tr className={classes.tr_th}>
                            <th className={classes.th}>Index</th>
                            <th className={classes.th}>ServiceName</th>
                            <th className={classes.th}>SubService Name</th>
                            <th className={classes.th}>Database</th>
                            <th className={classes.th}>Service Url</th>
                            <th className={classes.th}>Callback Url</th>
                            <th className={classes.th}>Price</th>
                            <th className={classes.th}>Pack</th>
                            <th className={classes.th}>Frontend Path</th>
                            <th className={classes.th}>Backend Path</th>
                            <th className={classes.th}>Description</th>
                            <th className={classes.th}>Country</th>
                            <th className={classes.th}>Operator</th>
                            <th className={classes.th}>EmpId</th>
                            <th className={classes.th}>Service Video Url</th>
                            <th className={classes.th}>Server Ip</th>
                            <th className={classes.th}>Promotion Url</th>
                            <th className={classes.th}>Promotion Start Date</th>
                            <th className={classes.th}>Last Promotion Date</th>
                            <th className={classes.th}>Callback Request</th>
                            <th className={classes.th}>Callback Response</th>
                            <th className={classes.th}>Charging Status</th>
                            <th className={classes.th}>Charging Api</th>
                            <th className={classes.th}>Charging Request</th>
                            <th className={classes.th}>Charging Response</th>
                            <th className={classes.th}>Free Trial</th>
                            <th className={classes.th}>Free Trial Days</th>
                            <th className={classes.th}>Edit</th>
                          </tr>

                          {data.serviceDetailsInfo?.map((dataItem, idx) => {
                            return (
                              <tr className={classes.tr} key={idx}>
                                <td className={classes.td}>{idx + 1}</td>
                                <td className={classes.td}>
                                  {dataItem?.serviceName}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.subServiceName}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.database}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.serviceUrl}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.callbackUrl}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.price}
                                </td>

                                <td className={classes.td}>{dataItem?.pack}</td>
                                <td className={classes.td}>
                                  {dataItem?.frontendpath}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.backendpath}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.description}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.country}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.operator}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.empId}
                                </td>

                                <td className={classes.td}>
                                  {dataItem?.serviceVideoUrl}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.serverIp}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.promotionUrl}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.promotionStartDate}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.lastPromotionDate}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.callbackRequest}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.callbackResponse}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.chargingStatus}
                                </td>

                                <td className={classes.td}>
                                  {dataItem?.chargingApi}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.chargingRequest}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.chargingResponse}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.freeTrail}
                                </td>
                                <td className={classes.td}>
                                  {dataItem?.freeTrailDays}
                                </td>

                                <td className={classes.td}>
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      setEditServiceDetailsModal({
                                        state: true,
                                        idx: idx,
                                      })
                                    }
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <JsonView
                    data={data}
                    shouldExpandNode={allExpanded}
                    style={darkStyles}
                  />
                </div>

                <ServiceDetailsInfoModal
                  setServiceDetailsInfo={setServiceDetailsInfo}
                  serviceDetailsInfo={serviceDetailsInfo}
                  serviceDetailsInfoModal={serviceDetailsInfoModal}
                  addServiceDetailsInfo={addServiceDetailsInfo}
                  closeServiceDetailsInfoModal={closeServiceDetailsInfoModal}
                />
                <EditServiceDetailsModal
                  editServiceDetailsModal={editServiceDetailsModal}
                  closeEditServiceDetailsModal={closeEditServiceDetailsModal}
                  data={data}
                  setServiceDetailsInfo={setServiceDetailsInfo}
                  serviceDetailsInfo={serviceDetailsInfo}
                  handleEditTheServiceDetails={handleEditTheServiceDetails}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ServiceModal;
