import React, { useEffect, useState } from "react";
import classes from "./ServiceAdministration.module.css";
import {
  fetchClientServices,
  fetchClientSubServicesApi,
  getServiceInfoDetailsApi,
  sendDataApi,
} from "../../Data/Api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import PostSecure from "../../Request/PostSecure";
import Loader from "../../NewComponents/Loading-States/Loader";
import NewSidebarAdmin from "../../NewComponents/Sidebar/NewSidebarAdmin";
import NewHeaderAdmin from "../../NewComponents/Header/NewHeaderAdmin";
import { Dropdown } from "primereact/dropdown";
import TitleHeader from "../../NewComponents/Header/TitleHeader";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ServiceModal from "../../NewComponents/ServiceModal/ServiceModal";

const ServiceAdministration = () => {
  const [open, setOpen] = useState(false);
  const closeHandler = () => {
    setOpen(false);
    setHitApi(false);
    setIdOfSubService(null);
  };

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");
  const [clientForDropdown, setClientForDropdown] = useState("");

  const [subServices, setSubServices] = useState([]);
  const [subService, setSubService] = useState("");

  const [tabIndex, setTabIndex] = useState(0);

  const [sidebarHide, setSidebarHide] = useState(() =>
    localStorage.getItem("sidebar")
      ? JSON.parse(localStorage.getItem("sidebar"))
      : false
  );
  const sidebarHandler = () => {
    localStorage.setItem("sidebar", JSON.stringify(!sidebarHide));
    setSidebarHide(JSON.parse(localStorage.getItem("sidebar")));
  };

  //to start on load
  useEffect(() => {
    const clients_variable = JSON.parse(localStorage.getItem("clients"));
    const countries_variables = clients_variable[0]?.countries;
    setCountries(countries_variables);
    setCountry(clients_variable[0]?.countries[0]);
    setClients(clients_variable);
    setClient(clients_variable[0]?.id);
    setClientForDropdown(clients_variable[0]);
    // gettingClientServices(clients_variable[0].id);
    gettingClientServices(
      clients_variable[0].id,
      clients_variable[0]?.countries[0]
    );
  }, []);

  //Hook to store services
  const [services, setServices] = useState([]);

  //Hook to store biggest value
  const [biggest, setBiggest] = useState(0);
  const [biggestRenewal, setBiggestRenewal] = useState(0);
  const [biggestSubscription, setBiggestSubscription] = useState(0);

  async function gettingClientServices(clientId, countryName) {
    try {
      setLoader("block");
      //fetch Services of that client and store in localStorage;
      // gettingServices() function call;
      let token = localStorage.getItem("userToken");
      let headers = { Authorization: "Bearer " + token };
      const res = await axios.post(
        `${fetchClientServices}?clientId=${clientId}`,
        data,
        {
          headers: headers,
        }
      );
      localStorage.setItem("services", JSON.stringify(res?.data?.data));
      gettingServices(countryName);
    } catch (error) {
      setLoader("none");
      if (error?.response?.status == 403) {
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      } else {
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
      }
    }
  }

  //Getting Services
  const gettingServices = (countryName) => {
    let services2 = JSON.parse(localStorage.getItem("services"));
    let filteredServices = services2.filter(
      (data) => data?.country == countryName
    );
    setServices(filteredServices);
    getDataFromBackend(filteredServices[0]?.serviceName, services2);
  };

  //Hook to store dates
  const [dates, setDates] = useState({
    to: moment(new Date()).format("yyyy-MM-DD"),
    from: moment().subtract(30, "days").format("yyyy-MM-DD"),
  });

  const [startDateForCalendar, setStartDateForCalendar] = useState(
    moment().subtract(30, "days").toDate()
  );
  const [endDateForCalendar, setEndDateForCalendar] = useState(new Date());

  const [service, setService] = useState("");
  const [responseService, setResponseService] = useState("");

  const fetchSubServices = async (serviceid) => {
    try {
      let token = localStorage.getItem("userToken");

      let headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        `${fetchClientSubServicesApi}?mainServiceId=${serviceid}`,
        null,
        {
          headers: headers,
        }
      );

      setSubServices(response?.data?.data?.dataArray);
      setSubService(() => response?.data?.data?.dataArray[0]?.subServiceName);
      return response?.data?.data?.dataArray[0]?.subServiceName;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      if (error?.response?.status == 403) {
        toast.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error?.message ||
            error
        );
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      }
    }
  };

  //Method to get data from Backend
  const getDataFromBackend = async (service2, allServices) => {
    setService(service2);
    const serviceid = allServices.filter(
      (data) => data?.serviceName == service2
    );

    if (serviceid.length > 0) {
      const subServiceValue = await fetchSubServices(serviceid[0]?.id);
      let data = {
        from: dates.from,
        to: dates.to,
        serviceName: service2,
        subServiceName: subServiceValue,
      };

      let promise = PostSecure(sendDataApi, data);
      promise
        .then((e) => {
          handleDataResponse(e);
        })
        .catch((err) => toast.error(err?.data?.message || err?.message || err));
    }
  };

  //   const getDataFromBackend2 = async (selectedSubService) => {
  //     setSubService(selectedSubService);
  //     let data = {
  //       from: dates.from,
  //       to: dates.to,
  //       serviceName: service,
  //       subServiceName: selectedSubService,
  //     };

  //     let promise = PostSecure(sendDataApi, data);
  //     promise
  //       .then((e) => {
  //         handleDataResponse(e);
  //       })
  //       .catch((err) => toast.error(err?.data?.message || err?.message || err));
  //   };

  //Hook to store data
  const [data, setData] = useState([]);

  //Method to handle response
  const handleDataResponse = (e) => {
    if (e.response === "error") {
      setLoader("none");
      if (e?.error?.response?.status == 403) {
        toast.error(e.error?.response?.data?.message || e.error?.message);
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      } else {
        toast.error(e.error?.response?.data?.message || e.error?.message);
      }
    } else {
      setLoader("none");
      const dataFromBackend = e.data;
      const dataDateManupulate = dataFromBackend.map((dataItem) => {
        return {
          id: dataItem?.id,
          misDate: dataItem?.misDate.substring(0, 10),
          totalBase: dataItem?.totalBase,
          totalActiveBase: dataItem?.totalActiveBase,
          subscriptions: dataItem?.subscriptions,
          unsubscriptions: dataItem?.unsubscriptions,
          renewalsRevenue: dataItem?.renewalsRevenue,
          renewals: dataItem?.renewals,
          subscriptionRevenue: dataItem?.subscriptionRevenue,
          totalRevenue: dataItem?.totalRevenue,
          totalRevenueAccumulated: dataItem?.DailyIncreaseAccumulated,
        };
      });

      const dataLimit = dataDateManupulate.slice(0, 33);
      setData(dataLimit.reverse());

      const biggestValue = Math.max.apply(
        Math,
        dataLimit.map(function (dataItem) {
          return dataItem.totalRevenue;
        })
      );
      setResponseService(e.service);
      setBiggest(biggestValue);
      const biggestValueRenewal = Math.max.apply(
        Math,
        dataLimit.map(function (dataItem) {
          return dataItem.renewalsRevenue;
        })
      );
      setBiggestRenewal(biggestValueRenewal);
      const biggestValueSubscription = Math.max.apply(
        Math,
        dataLimit.map(function (dataItem) {
          return dataItem.subscriptionRevenue;
        })
      );
      setBiggestSubscription(biggestValueSubscription);
    }
  };

  //Hook to store loader div state
  const [loader, setLoader] = useState("block");

  const handleServiceChange = (service) => {
    setLoader("block");
    let allServices = JSON.parse(localStorage.getItem("services"));
    getDataFromBackend(service, allServices);
  };

  const handleClientChange = (client) => {
    let username = client?.username;
    const clients_variable = JSON.parse(localStorage.getItem("clients"));
    const countries_variables = clients_variable?.filter(
      (data) => data?.username == username
    );
    setCountries(countries_variables[0]?.countries);
    setCountry(countries_variables[0]?.countries[0]);
    setClientForDropdown(client);
    setClient(client?.id);
    gettingClientServices(client?.id, countries_variables[0]?.countries[0]);
  };

  const handleCountryChange = (selectedCountry) => {
    let services2 = JSON.parse(localStorage.getItem("services"));
    let filteredServices = services2.filter(
      (data) => data?.country == selectedCountry
    );
    setCountry(selectedCountry);
    setServices(filteredServices);
    getDataFromBackend(filteredServices[0]?.serviceName, services2);
  };

  console.log(services, "s");
  console.log(subServices, "s");
  //   const handleSubServiceChange = (selectedSubService) => {
  // getDataFromBackend2(selectedSubService);
  //   };

  const [hitApi, setHitApi] = useState(false);
  const [idOfSubService, setIdOfSubService] = useState(null);

  const handleSubServiceClick = (id) => {
    setHitApi(true);
    // setIdOfSubService(id);
    setOpen(true);
  };

  const [subServiceDetails, setSubServiceDetails] = useState([]);

  const handleSubService = async (id) => {
    try {
      setLoader("block");
      let token = localStorage.getItem("userToken");
      let headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        `${getServiceInfoDetailsApi}`,
        {
          id: id,
        },
        {
          headers: headers,
        }
      );

      setIdOfSubService(id);
      setSubServiceDetails(response?.data?.data?.serviceDetailsInfo);

      setLoader("none");

      console.log(response, "response...");
    } catch (error) {
      setLoader("none");

      console.log(error, "error...");
    }
  };

  return (
    <>
      <Loader value={loader} />
      <ToastContainer />
      <div className={`${classes.main} ${sidebarHide && classes.short}`}>
        <div className={`${classes.sidebar} ${sidebarHide && classes.short}`}>
          <div
            className={`${classes.sidebar_header} ${
              sidebarHide && classes.short
            }`}
          >
            <img
              src="/assets/images/logo1.png"
              alt="Revenue portal"
              className={classes.sidebar_logo}
            />
            <h3 className={classes.dashboard_text}>Dashboard</h3>
          </div>
          <div className={classes.sidebar_icon}>
            <div className={classes.circle} onClick={sidebarHandler}>
              {sidebarHide ? (
                <i
                  className={`fa-solid fa-arrow-right ${classes.arrow_icon}`}
                ></i>
              ) : (
                <i
                  className={`fa-solid fa-arrow-left ${classes.arrow_icon}`}
                ></i>
              )}
            </div>
          </div>
          <NewSidebarAdmin highlight={5} sidebarHide={sidebarHide} />
        </div>
        <div className={classes.container}>
          <NewHeaderAdmin service={responseService} highlight={5} />
          <div className={classes.sub_container}>
            <form className={classes.form}>
              <div className={classes.client}>
                <Dropdown
                  value={clientForDropdown}
                  onChange={(e) => handleClientChange(e.value)}
                  options={clients?.map((client) => ({
                    label: client?.clientName,
                    value: client,
                  }))}
                  placeholder="Select a Client"
                  style={{ width: "100%" }}
                />
              </div>

              <div className={classes.client}>
                <Dropdown
                  value={country}
                  onChange={(e) => handleCountryChange(e.value)}
                  options={countries?.map((data) => ({
                    label: data,
                    value: data,
                  }))}
                  placeholder="Select a Country"
                  style={{ width: "100%" }}
                />
              </div>

              <div className={classes.service}>
                <Dropdown
                  value={service}
                  onChange={(e) => handleServiceChange(e.value)}
                  options={services?.map((service) => ({
                    label: service?.serviceName,
                    value: service?.serviceName,
                  }))}
                  placeholder="Select a Service"
                  style={{ width: "100%" }}
                />
              </div>

              {/* <div className={classes.service}>
                <Dropdown
                  value={subService}
                  onChange={(e) => handleSubServiceChange(e.target.value)}
                  options={subServices?.map((service) => ({
                    label: service?.subServiceName,
                    value: service?.subServiceName,
                  }))}
                  placeholder="Select a Sub Service"
                  style={{ width: "100%" }}
                />
              </div> */}
            </form>

            <TitleHeader title={`(${service}) Sub-Services`} icon="" />

            <button className={classes.add_btn} onClick={() => setOpen(true)}>
              Add New Service
              <AddIcon />
            </button>

            <div className={classes.flex_container}>
              {subServices.map((service, i) => {
                return (
                  <div key={i} className={classes.flex_item}>
                    <Button
                      variant="outlined"
                      // color="error"
                      size="medium"
                      onClick={() => handleSubService(service?.id)}
                      // onClick={() => handleSubServiceClick(service?.id)}
                    >
                      {service.subServiceName}
                    </Button>
                  </div>
                );
              })}
            </div>

            <div className={classes.table_container}>
              <div className={classes.table_sub_container}>
                <table className={classes.table}>
                  <tbody>
                    <tr className={classes.tr_th}>
                      <th className={classes.th}>Edit</th>
                      <th className={classes.th}>serviceName</th>
                      <th className={classes.th}>subServiceName</th>
                      <th className={classes.th}>database</th>
                      <th className={classes.th}>serviceUrl</th>
                      <th className={classes.th}>callbackUrl</th>
                      <th className={classes.th}>price</th>
                      <th className={classes.th}>pack</th>
                      <th className={classes.th}>frontendpath</th>
                      <th className={classes.th}>backendpath</th>
                      <th className={classes.th}>description</th>
                      <th className={classes.th}>country</th>
                      <th className={classes.th}>operator</th>
                      <th className={classes.th}>empId</th>
                      <th className={classes.th}>serviceVideoUrl</th>
                      <th className={classes.th}>addServerByAdminApi</th>
                      <th className={classes.th}>promotionUrl</th>
                      <th className={classes.th}>promotionStartDate</th>
                      <th className={classes.th}>lastPromotionDate</th>
                      <th className={classes.th}>callbackRequest</th>
                      <th className={classes.th}>callbackResponse</th>
                      <th className={classes.th}>chargingStatus</th>
                      <th className={classes.th}>chargingApi</th>
                      <th className={classes.th}>chargingRequest</th>
                      <th className={classes.th}>chargingResponse</th>
                      <th className={classes.th}>freeTrail</th>
                      <th className={classes.th}>freeTrailDays</th>
                    </tr>

                    {subServiceDetails?.map((data, idx) => {
                      return (
                        <tr className={classes.tr} key={idx}>
                            <td className={classes.td}>
                            <Button
                              variant="contained"
                              onClick={() =>
                                handleSubServiceClick(idOfSubService)
                              }
                            >
                              Edit
                            </Button>
                          </td>
                          <td className={classes.td}>{data.serviceName}</td>
                          <td className={classes.td}>{data.subServiceName}</td>
                          <td className={classes.td}>{data.database}</td>
                          <td className={classes.td} style={{ width: "100%" }}>
                            {data.serviceUrl}
                          </td>
                          <td className={classes.td} style={{ width: "100%" }}>
                            {data.callbackUrl}
                          </td>
                          <td className={classes.td}>{data.price}</td>
                          <td className={classes.td}>{data.pack}</td>
                          <td className={classes.td}>{data.frontendpath}</td>
                          <td className={classes.td}>{data.backendpath}</td>
                          <td className={classes.td}>{data?.description}</td>
                          <td className={classes.td}>{data?.country}</td>
                          <td className={classes.td}>{data?.operator}</td>
                          <td className={classes.td}>{data?.empId}</td>
                          <td className={classes.td}>
                            {data?.serviceVideoUrl}
                          </td>
                          <td className={classes.td}>{data?.serverIp}</td>
                          <td className={classes.td}>{data?.promotionUrl}</td>
                          <td className={classes.td}>
                            {data?.promotionStartDate}
                          </td>
                          <td className={classes.td}>
                            {data?.lastPromotionDate}
                          </td>
                          <td className={classes.td}>
                            {data?.callbackRequest}
                          </td>
                          <td className={classes.td}>
                            {data?.callbackResponse}
                          </td>
                          <td className={classes.td}>{data?.chargingStatus}</td>
                          <td className={classes.td}>{data?.chargingApi}</td>
                          <td className={classes.td}>
                            {data?.chargingRequest}
                          </td>
                          <td className={classes.td}>
                            {data?.chargingResponse}
                          </td>
                          <td className={classes.td}>{data?.freeTrail}</td>
                          <td className={classes.td}>{data?.freeTrailDays}</td>
                        
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
         
          </div>
        </div>
      </div>
      <ServiceModal
        open={open}
        closeHandler={closeHandler}
        hitApi={hitApi}
        idOfSubService={idOfSubService}
      />
    </>
  );
};

export default ServiceAdministration;
